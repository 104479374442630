import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import time from '@flyer/utils/time';
import { notification } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useAtom } from 'jotai';
import Toast from '@flyer/components/toast/toast';
import { useRouter } from 'next/router';
import { m } from 'framer-motion';
import getMediaUrl from '@/lib/utils';
import IconClose from '@/assets/svg/ic-close-black.svg';
import {
  NotificationSource,
  NotificationType,
  ViewerExamFloatNotificationQueryQuery,
} from '@/__generated__/graphql';
import { graphql } from '@/__generated__';
import useWindowWidth from '@/hooks/useWindowWidth';
import { useAuth } from '@/providers/auth';
import { totalNotification } from '@/store/home';
import { useGetChallengeByCodeQueryLazyQuery } from '@/graphql/generated/query';
import ROUTES from '@/lib/routes';

const ViewerExamFloatNotification = graphql(`
  query ViewerExamFloatNotificationQuery(
    $after: Cursor
    $before: Cursor
    $first: Int
    $last: Int
    $filter: NotificationFilter
  ) {
    viewerExamNotifications(
      after: $after
      before: $before
      first: $first
      last: $last
      filter: $filter
    ) {
      edges {
        cursor
        node {
          id
          meta {
            linkExam
            attemptID
            challengeCode
          }
          description
          descriptionEn
          title
          titleEn
          image
          seen
          type
          source
          createdAt
        }
      }
    }
  }
`);

const UpdateSeenMutation = graphql(`
  mutation ViewerExamSeenNotificationMutation($input: ViewerExamSeenNotificationInput!) {
    viewerExamSeenNotification(input: $input) {
      notification {
        id
      }
      userErrors {
        code
        message
      }
    }
  }
`);

const CountNoti = graphql(`
  query ViewerExamUnreadNotificationsCountQuery {
    viewerExamUnreadNotificationsCount
  }
`);

type NotificationEdge =
  ViewerExamFloatNotificationQueryQuery['viewerExamNotifications']['edges'][number];

const pollInterval = 20_000;

export default function FloatNotification() {
  const { t, lang } = useTranslation('modal');
  const windowWidth = useWindowWidth();
  const { user, isLogin } = useAuth();
  const router = useRouter();
  const [, setCount] = useAtom(totalNotification);

  const [notificationApi, context] = notification.useNotification({
    stack: { threshold: 2 },
  });
  const [updateSeenMutation] = useMutation(UpdateSeenMutation, { context: { v2: true } });

  const [joinChallengeByCode, { loading: joinChallengeLoading }] =
    useGetChallengeByCodeQueryLazyQuery({
      onCompleted(data) {
        if (!data.challenges[0]) {
          Toast.error(t('error:label.join_challenge_invalid_code'));
          return;
        }

        void router.push(`${ROUTES.JOIN_CHALLENGE(data.challenges[0].code)}`);
      },
    });

  const handleOnClickCell = useCallback((cell: NotificationEdge) => {}, []);

  const updateSeen = useCallback(
    (cellId?: string) => {
      void updateSeenMutation({
        variables: {
          input: {
            all: !cellId,
            id: cellId,
          },
        },
        onCompleted: () => {
          if (cellId) {
            setCount((prev) => Math.max(0, prev - 1));
          } else {
            setCount(0);
          }
        },
      });
    },
    [setCount, updateSeenMutation],
  );

  const handleClose = useCallback(
    (cellId: string) => {
      notificationApi.destroy(cellId);
    },
    [notificationApi],
  );

  const renderSource = useCallback(
    (source?: NotificationSource) => {
      if (source === NotificationSource.Partner) {
        return t('notification.label.from_teacher');
      }

      if (source === NotificationSource.Flyer) {
        return t('notification.label.from_flyer');
      }

      return 'Flyer';
    },
    [t],
  );

  const renderTitle = useCallback(
    (cell: NotificationEdge) => {
      if (lang === 'vi') {
        return cell.node.title;
      }
      return cell.node.titleEn;
    },
    [lang],
  );

  const renderDescription = useCallback(
    (cell: NotificationEdge) => {
      if (lang === 'vi') {
        return cell.node.description;
      }
      return cell.node.descriptionEn;
    },
    [lang],
  );

  const renderItem = useCallback(
    (cell: NotificationEdge) => {
      return (
        <div className="">
          <div
            role="cell"
            className={twMerge(
              'flex rounded-[8px] cursor-pointer gap-[8px] items-top w-[99%] transition-colors duration-150',
            )}
            onClick={() => handleOnClickCell(cell)}
            aria-hidden="true"
            key={cell.node.id}
          >
            <Image
              src={
                cell.node.image
                  ? getMediaUrl(cell.node.image)
                  : 'https://www.flyer.vn/wp-content/uploads/2022/03/cropped-Bingo-logo.png'
              }
              className="rounded-[8px] w-[45px] h-[45px]"
              alt="noti thumb"
              width={150}
              height={100}
              objectFit="cover"
            />

            <div className="text-left text-white w-[100%]">
              <div className="text-[12px] font-[500] flex justify-between">
                <span className="leading-[20px] -translate-y-1">
                  {renderSource(cell.node.source)}
                  {cell.node.createdAt && (
                    <>
                      <span className="mx-1 font-bold text-[24px] relative top-1">&#183;</span>
                      <span className="italic text-xs font-normal">
                        {time(cell.node.createdAt).locale(lang).format('DD MMM YYYY')}
                      </span>
                    </>
                  )}
                </span>

                <IconClose
                  width={17}
                  height={17}
                  alt="modal close"
                  fill="white"
                  className="transition-colors duration-300 hover:fill-[#37F9F7] w-[9px] h-[9px] sm:w-[17px] sm:h-[17px]"
                  onClick={() => handleClose(cell.node.id)}
                />
              </div>
              <div className="text-[14px] font-[700] mb-[2px]">{renderTitle(cell)}</div>
              <div className="text-[12px] font-[600]">{renderDescription(cell)}</div>
            </div>
          </div>

          {cell?.node?.meta?.challengeCode &&
            cell.node.type === NotificationType.ChallengeInvite && (
              <div className="flex justify-end">
                <m.div
                  whileTap={{ scale: 0.9 }}
                  onClick={() => {
                    if (joinChallengeLoading) return;
                    void joinChallengeByCode({
                      variables: {
                        code: cell?.node?.meta?.challengeCode?.trim(),
                      },
                    });
                    handleClose(cell.node.id);
                  }}
                  className="border border-[#FFB6B2] bg-[#EE857F] rounded shadow-[0px_3px_0px_0px_#EE857F] text-white px-3 py-[2px] cursor-pointer hover:opacity-80"
                >
                  <span>{t('notification.label.join_challenge')}</span>
                </m.div>
              </div>
            )}
        </div>
      );
    },
    [
      handleClose,
      handleOnClickCell,
      joinChallengeByCode,
      joinChallengeLoading,
      lang,
      renderDescription,
      renderSource,
      renderTitle,
      t,
    ],
  );

  const handleOpenNotification = useCallback(
    (cell: NotificationEdge) => {
      notificationApi.open({
        message: null,
        duration: 3,
        // duration: null,
        closeIcon: null,
        style: {
          background: 'linear-gradient(95deg, #AE59E0 0.33%, #FD8C88 99.67%)',
          borderRadius: '7px',
          padding: '2px 10px 10px',
        },
        description: renderItem(cell),
      });
    },
    [notificationApi, renderItem],
  );

  const [getNotifications] = useLazyQuery(ViewerExamFloatNotification, {
    variables: {
      first: 5,
      filter: {
        seen: false,
      },
    },
    context: { v2: true },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const unseenNotifications = data.viewerExamNotifications.edges;

      for (const notificationItem of unseenNotifications) {
        handleOpenNotification(notificationItem);
      }

      setTimeout(updateSeen, 3000);
    },
  });

  const skipQueryNoti = useMemo(
    () => !user?.currentLearnerProfile?.id && !isLogin,
    [isLogin, user?.currentLearnerProfile?.id],
  );

  useQuery(CountNoti, {
    context: {
      v2: true,
    },
    pollInterval,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: skipQueryNoti,
    onCompleted: ({ viewerExamUnreadNotificationsCount }) => {
      const newCount = viewerExamUnreadNotificationsCount || 0;
      setCount(newCount);

      if (newCount > 0 && windowWidth >= 768) {
        void getNotifications();
      }
    },
  });

  return <div>{context}</div>;
}
